<template>
  <div>
    <b-row>
      <b-col>
        <div class="card card-custom card-stretch gutter-b">
    <!--begin::Header-->
    <div class="card-header border-0 py-5">
      <h3 class="card-title align-items-start flex-column">
        <span class="card-label font-weight-bolder text-dark font-poppins">Customer Messages</span>
      </h3>
    </div>
    <!--end::Header-->
    <!--begin::Body-->
    <div class="card-body pt-0 pb-3">
      <div class="tab-content">
        <!--begin::Table-->
        <div class="table-responsive">
          <table
            class="
              table
              table-head-custom
              table-vertical-center
              table-head-bg
              table-borderless
            "
          >
            <thead>
              <tr class="text-left">
                <th style="min-width: 120px">Name</th>
                <th style="min-width: 120px">Email</th>
                <th style="min-width: 100px">Telephone</th>
                <th style="min-width: 100px">Date</th>
                <th style="min-width: 100px">Message</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              <template v-for="(item, i) in messages">
                <tr v-bind:key="i">
                  <td>
                    <span
                      class="
                        text-dark-75
                        d-block
                        font-size-lg
                      "
                    >
                      {{ item.full_name }}
                    </span>
                  </td>
                  <td>
                    <span
                      class="
                        text-dark-75
                        d-block
                        font-size-lg
                      "
                    >
                      {{ item.email }}
                    </span>
                  </td>
                  <td>
                    <span
                      class="
                        text-dark-75
                        d-block
                        font-size-lg
                      "
                    >
                      {{ item.telephone }}
                    </span>
                  </td>
                  <td>
                    <span
                      class="
                        text-dark-75
                        d-block
                        font-size-lg
                      "
                    >
                      {{ item.created_at }}
                    </span>
                  </td>
                    <td>
                    <span
                      class="
                        text-dark-75
                        d-block
                        font-size-lg
                      "
                    >
                      {{ item.message }}
                    </span>
                  </td>
                  <td>
                      <a
                        @click="deleteMessage(item.id)"
                        class="btn btn-light-danger font-size-xl"
                        ><i class="flaticon-delete"></i
                      ></a>
                  </td>
                </tr>
              </template>
            </tbody>
          </table>
        </div>
        <!--end::Table-->
      </div>
    </div>
    <!--end::Body-->
  </div>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import { BUSINESS_MESSAGES , DELETE_MESSAGE } from "@/core/services/store/config.module";
import { mapGetters } from "vuex";
import Swal from "sweetalert2";
export default {
  name: "Messages",
  computed: {
    ...mapGetters(["currentUser" , "getDate"])
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [
      { title: "Messages" },
      { title: "All Messages" }
    ]);

    // look for current user
    if (this.currentUser.token !== "") {
      // call method to get orders
      this.fetchMessages();
    }
  },

  data() {
    return {
      messages : []
    };
  },
  methods: {
    deleteMessage(id) {
      this.$store
        .dispatch(DELETE_MESSAGE , {
          id : id
        })
        // go to which page after successfully login
        .then(resp => {
          Swal.fire("Confirmation", 'Message Deleted Successfully', "success");
          this.fetchMessages();
        })
        .catch(error => {
          Swal.fire("Exception", 'Something Went Wrong', "error");

          console.log(error);
        });
    },
    fetchMessages() {

      this.$store
        .dispatch(BUSINESS_MESSAGES)
        // go to which page after successfully login
        .then(resp => {
          this.messages = resp          // this.$router.push({ name: "dashboard" })
        })
        .catch(error => {
          console.log(error);
        });
    }
  }
};
</script>
